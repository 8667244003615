import React, { useEffect, useState } from 'react';
import axios from 'axios';
import config from '../configuration/config'; // Adjust the path as needed

const BrandList = () => {
    const [brands, setBrands] = useState([]);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await axios.get(`${config.API_BASE_URL}/brands`);
                setBrands(response.data);
            } catch (error) {
                console.error('Error fetching brands:', error);
            }
        };

        fetchBrands();
    }, []);

    return (
        <div className="tp-brand-3-area pb-120">
            <div className="container container-1400">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="tp-brand-3-slider-wrapper">
                            <div className="swiper-container tp-brand-3-slider-active fix">
                                <div className="swiper-wrapper brand-wrapper">
                                    {brands.map((brand) =>
                                        brand.images.map((img, imgIndex) => (
                                            <div className="swiper-slide" key={`${brand.id}-${imgIndex}`}>
                                                <div className="tp-brand-item">
                                                    <img
                                                        src={`${config.API_BASE_URL_IMAGE}${img.image}`}  // Use filename directly
                                                        alt={`Brand ${brand.name} Image ${imgIndex}`}
                                                        style={{ width: '100px', height: '100px', margin: '5px' }}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandList;


