import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
/*import Footer from './components/Footer';*/

import HomePage from './pages/HomePage';
import BrandForm from './pages/BrandForm';
import Submissions from './pages/Submissions';
import ContactPage from './pages/ContactPage';

function App() {
    useEffect(() => {
        const loadScript = (src) => {
            return new Promise((resolve, reject) => {
                const script = document.createElement('script');
                script.src = `${process.env.PUBLIC_URL}/${src}`;
                script.async = true;
                script.onload = () => resolve();
                script.onerror = () => reject(new Error(`Failed to load script ${src}`));
                document.body.appendChild(script);
            });
        };

        const loadScriptsSequentially = async () => {
            try {
                // Load jQuery first
                await loadScript('assets/js/vendor/jquery.js');

                // Load scripts that depend on jQuery
                await Promise.all([
                    loadScript('assets/js/bootstrap-bundle.js'),
                    loadScript('assets/js/range-slider.js'),
                    loadScript('assets/js/slick.js'),
                    loadScript('assets/js/magnific-popup.js'),
                    loadScript('assets/js/nice-select.js'),
                    loadScript('assets/js/beforeafter.js'),
                    loadScript('assets/js/ajax-form.js'),
                    loadScript('assets/js/tp-cursor.js'),
                ]);

                // Load remaining scripts
                await Promise.all([
                    loadScript('assets/js/gsap.js'),
                    loadScript('assets/js/gsap-scroll-to-plugin.js'),
                    loadScript('assets/js/gsap-scroll-smoother.js'),
                    loadScript('assets/js/gsap-scroll-trigger.js'),
                    loadScript('assets/js/gsap-split-text.js'),
                    loadScript('assets/js/chroma.min.js'),
                    loadScript('assets/js/three.js'),
                    loadScript('assets/js/tween-max.js'),
                    loadScript('assets/js/scroll-magic.js'),
                    loadScript('assets/js/swiper-bundle.js'),
                    loadScript('assets/js/purecounter.js'),
                    loadScript('assets/js/isotope-pkgd.js'),
                    loadScript('assets/js/imagesloaded-pkgd.js'),
                    loadScript('assets/js/webgl.js'),
                    loadScript('assets/js/main.js'),
                ]);
            } catch (error) {
                console.error('Error loading scripts:', error);
            }
        };

        loadScriptsSequentially();

        // Cleanup on unmount
        return () => {
            const scriptsToRemove = document.body.querySelectorAll('script[src*="assets/js/"]');
            scriptsToRemove.forEach(script => document.body.removeChild(script));
        };
    }, []);

    return (
        <Router>
            <Routes>
                <Route
                    path="/"
                    element={
                        <>
                            <Header />
                            <HomePage />
                        </>
                    }
                />
                <Route
                    path="/brandform"
                    element={<BrandForm />}
                />
                <Route
                    path="/Submissions"
                    element={<Submissions />}
                />
                <Route path="/ContactPage" element={
                    <>
                        <Header />
                        <ContactPage />
                    </>
                } />
            </Routes>
            {/*<Footer />*/}
        </Router>
    );
}

export default App;
